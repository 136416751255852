@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

* {
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: 'Quicksand', sans-serif; */
  /* font-family: 'Mukta', sans-serif; */
  font-weight: lighter !important;
  /* font-family: 'Arimo', sans-serif !important; */
  /* font-family: 'GFS Didot', serif !important; */
  font-family: "Josefin Sans", sans-serif !important;
  /* font-family: "Poppins", sans-serif !important; */
  /*  font-family: Libre Baskerville, serif !important; */
  /* font-family: "Nunito Sans", sans-serif !important; */
}

/* Scroll Bar CSS */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(242, 242, 242, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(166, 166, 166, 1);
  border-radius: 5px;
  opacity: 0.2;
}

.slider-container {
  display: inline-block;
}

.shake {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.centerdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-slider .slick-track {
  display: flex !important;
  gap: 10px;
}

.slick-prev:before {
  color: blue !important;
}

.slick-next:before {
  color: blue !important;
}

.accordion .MuiPaper-root {
  box-shadow: none !important;
}

.filter-dark .MuiTypography-root {
  color: white !important;
}

.filter-light .MuiTypography-root {
  color: black !important;
}

.popper .MuiPaper-root {
  width: 100% !important;
  left: 0 !important;
  top: 145px !important;
  margin-left: 15px;
}

.tab .MuiTabs-indicator {
  bottom: 46px !important;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.shubhga-carousel button {
  height: 50px !important;
  width: 50px !important;
  margin: 10px 20px !important;
  background: black !important;
  display: flex !important;
  top: 42% !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 100% !important;
  font-size: 10px !important;
}

@media only screen and (max-width: 600px) {
  .shubhga-carousel button {
    scale: 0 !important;
  }
}

.shubhga-carousel button :hover {
  background: black !important;
}

.icon {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 50%;
  stroke: #50e38e;
  margin: 50px auto;
  -webkit-animation: bounceIcon 0.17s ease-in-out;
  animation: bounceIcon 0.17s ease-in-out;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

/* .circle {
  stroke-dasharray: 190;
  stroke-dashoffset: 190;
  stroke-width: 9;
  stroke: #ece8e8;
  fill: none;
  -webkit-animation: drawCircle 0.2s linear;
  animation: drawCircle 0.2s linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
} */

.check {
  stroke-width: 7;
  stroke-dasharray: 120;
  stroke-dashoffset: 120;
  -webkit-animation: drawCheckmark 0.2s cubic-bezier(0.89, 0.09, 0.91, 0.67);
  animation: drawCheckmark 0.2s cubic-bezier(0.89, 0.09, 0.91, 0.67) forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@keyframes drawCircle {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheckmark {
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes bounceIcon {
  0% {
    transform: none;
  }
  50% {
    transform: scale3D(0.9, 0.9, 1);
  }
  100% {
    transform: none;
  }
}

.class-name {
  display: block;
  white-space: nowrap;
  width: 11.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.class-name-wishlist {
  display: block;
  white-space: nowrap;
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.perimeter {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.image {
  flex: 0 0 100%;
}

.copy {
  padding: 20px;
  font-family: sans-serif;
}

@media (min-width: 415px) {
  .perimeter {
    flex-direction: row;
  }

  .image {
    flex: 0 0 50%;
  }
}

@media (min-width: 800px) {
  .image {
    flex: 0 0 33.5%;
  }
}

.testimonial-carousel button {
  display: none !important;
}
.gradient {
  background: linear-gradient(180deg, pink, violet);
}
.Product .MuiInputBase-root {
  border-radius: 50px !important;
  padding: 8px 20px !important;
}
